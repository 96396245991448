<template>
  <div class="blog">
    <h1>This is a blogs page</h1>
  </div>
</template>

<script>
export default {
  name: "Blog",

  components: {},
};
</script>